/** Custom classes for Quick Info Modal Template */
.e-schedule-custom-quick-info-row {
  display: flex; 
  align-items: center;
  margin-bottom: 7px;
}

/** Override: Native Syncfusion Quick Info Modal Template Classes */
.e-quick-popup-wrapper {
  border-radius: 12px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header {
  background: var(--op2mise-color-primary);
  margin-bottom: 25px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper {
  padding: 10px 20px 0 20px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-subject-wrap {
  padding: 0px 24px 0 6px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-subject-wrap .e-subject {
  background: transparent;
  border-radius: 0;
  border-left: 0;
  color: var(--op2mise-color-white);
  padding-left: 14px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 41px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-edit-icon, .e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-delete-icon, .e-quick-popup-wrapper .e-event-popup .e-popup-header .e-header-icon-wrapper .e-close-icon {
  font-size: 16px;
  color: var(--op2mise-color-white);
}

.e-quick-popup-wrapper .e-popup-footer {
  padding: 16px 12px 16px 40px;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-content {
  padding: 0px 24px;
}