.body {
    padding: 30px 50px;
}

.body label {
    font-weight: 300;
    font-size: 20px;
}

.smallText,
.fileName {
    font-weight: 200;
    font-size: 14px;
}

.fileName {
    margin-top: 36px;
    margin-left: 10px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.smallerText {
    font-weight: 400;
    font-size: 12px;
    color: #6D6D73;
    margin-top: 3px;
}

.buttons {
    text-align: right;
}

.description {
    font-weight: 100;
    font-size: 16px;
}

.confirmationText {
    font-weight: 300;
    font-size: 20px;
}

.saveButton {
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    color: #ffffff;
    background-color: #008662;
    border: none;
    border-radius: 4px;
  }

.cancelButton{  
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    background-color: #ffffff;
    color: #6d6d73;
    border: none;
    border-radius: 4px;
}

.marginTop30{
    margin-top: 30px;
}

.divHeader{
    text-align: center;
    padding-top: 20px;
  }

  .marginRight10{
    margin-right: 10px;
}