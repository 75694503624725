.e-input-group.e-control-wrapper.op-timepicker.e-time-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6D6D73;
    border: 1px solid #CED4DA !important;
    border-radius: 4px;
    padding: 0 10px;
    height: 33px;
}

.e-input-group.e-control-wrapper.op-timepicker.e-time-wrapper:has(input.e-disabled){
    padding: 0 10px;
    background-color: #CED4DA !important;
    color: #B1B1B1 !important;
}

.op-timepicker.e-time-wrapper input.e-control.e-timepicker.e-lib.e-input{
    font-size: 12px;
    color: #343a40;
}

/* This hides the clock icon from the TimeInputComponent */
span.e-input-group-icon.e-time-icon.e-icons{
    display: none;
}

.e-input-group.e-control-wrapper.e-valid-input.op-timepicker.e-disabled.e-time-wrapper > input.e-control.e-timepicker.e-lib.e-input.e-disabled{
    background-color: #CED4DA !important;
    color: #B1B1B1 !important;
    padding: 0 10px;
    height: 33px;
}