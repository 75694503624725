.op2mise-widget-form-section {
  border: solid 1px var(--op2mise-color-ash-gray);
  margin-bottom: 12px;
}

.op2mise-widget-form-section-header {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: var(--op2mise-color-primary);
  font-size: 18px;
  margin-bottom: 12px;
  padding: 16px;
}

.op2mise-widget-form-section-content {
  padding: 8px 16px;
}

.op2mise-widget-form-section-content-wrapper {
  margin-bottom: 12px;
  padding: 0px 4px;
}