.architecture-banner {
  padding-top: 15px;
  display: flex;
}

.architecture-banner-header-text {
  flex: 3;
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.architecture-banner-channel-selector {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.architecture-banner-channel-label {
  color: #6d6d73;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-right: 5px;
  padding-right: 10px;
}

.program-type-label {
  color: #6d6d73;
  font-size: 14px;
  font-weight: 400;
}

.e-custom-editor-label {
  flex: 1;
  color: #6d6d73;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-right: 5px;
}

.e-schedule-custom-event{
  height: 100%;
}

.e-schedule-custom-event.e-schedule-grading {
  container: GradingText / size;
  position: absolute;
  height: 100%;
  width: 101%;
  left: 0;
}

.e-schedule-custom-event-title.e-schedule-grading-title,
.e-schedule-custom-event-title.e-schedule-minutage-title {
  font-size: 7rem;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  letter-spacing: -3px;
  height: 100%;
  color: #FAFAFA;
  /* opacity: 100; */
}

@container (height < 110px) {
  .e-schedule-custom-event-title.e-schedule-grading-title {
    font-size: 55cqb;
    bottom: -25px;
    right: -3px;
  }
}

@container (height < 98px) {
  .e-schedule-custom-event-title.e-schedule-grading-title {
    font-size: 50cqb;
    bottom: -25px;
    right: -3px;
  }
}

@container (height < 50px) {
  .e-schedule-custom-event-title.e-schedule-grading-title {
    font-size: 50cqb;
    bottom: -15px;
    right: -1px;
    /* opacity: 25%; */
    letter-spacing: -3px;
  }
}

@container (height < 30px) {
  .e-schedule-custom-event-title.e-schedule-grading-title {
    font-size: 50cqb;
    bottom: -12px;
    right: -3px;
    /* opacity: 25%; */
    letter-spacing: -1px;
  }
}

div.e-appointment-details:has(.e-schedule-custom-event-title.e-schedule-minutage-title){
  height: 100%;
}

div:has(.e-schedule-custom-event-title) {
  height: 100%;
}

.e-schedule-custom-event-title.e-schedule-minutage-title{
  font-size: 45px;
  font-weight: 400;
  /* display: block;
    opacity: 50%;
    font-weight: 400;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 80%;
    text-align: center; */
  }

/* Scheduler Grading Block Bakcground Color */
.e-schedule-grade-10, 
.e-schedule-grade-9,
.e-schedule-grade-8,
.e-schedule-grade-7 {
  background-color: var(--op2mise-color-green);
}
.e-schedule-grade-6 {
  background-color: var(--op2mise-color-yellow-green);
}
.e-schedule-grade-5 {
  background-color: var(--op2mise-color-yellow);
}
.e-schedule-grade-4 {
  background-color: var(--op2mise-color-light-orange);
}
.e-schedule-grade-3 {
  background-color: var(--op2mise-color-orange);
}
.e-schedule-grade-2 {
  background-color: var(--op2mise-color-light-blue);
}
.e-schedule-grade-1 {
  background-color: var(--op2mise-color-dark-blue);
}

/* Scheduler Grading Text Position */
.e-schedule-grade-1 .e-schedule-grading-title {
  right: 0px;
  /* opacity: 35%; */
}

/* Scheduler Grading Text opacity */
/* .e-schedule-grade-6 .e-schedule-grading-title {
  opacity: 50%;
}
.e-schedule-grade-5 .e-schedule-grading-title{
  opacity: 60%;
}
.e-schedule-grade-4 .e-schedule-grading-title {
  opacity: 40%;
}
.e-schedule-grade-3 .e-schedule-grading-title, .e-schedule-grade-2 .e-schedule-grading-title {
  opacity: 35%;
} */

/* END Scheduler Grading Block Styling */


.icon-link-sequential {
  fill: var(--op2mise-color-white);
  filter: brightness(0) invert(1);
  cursor: pointer;
  margin: 0px 1px
}

/* Modal for Grading and Minutage - Button Styling */

.e-flat-btn{
  color: var(--op2mise-color-charcoal);
  border: 1px solid var(--op2mise-color-silver-gray);
  background-color: #ffffff;
  font-size: 12px;
  color: #6d6d73 !important;
  border: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.e-flat-btn:focus {
  outline: none;
}

.e-footer-content .e-btn:hover.e-flat:not([DISABLED]) {
  background: none;
}

.e-primary-btn{
  background-color: #008662 !important;
  font-size: 12px !important;
  font-weight: 100;
  border-radius: 4px;
  padding: 0 24px;
  line-height: 36px;
  border: none;
  color: #ffffff !important;
  font-weight: 400 !important;
  font-family: 'Poppins', sans-serif;
}

.e-primary-btn:focus {
  border: 1px solid #116c53;
  outline: none;
}

.e-btn.e-primary-btn.e-flat:hover, .e-css.e-primary-btn.e-btn.e-flat:hover {
  background: #116c53 !important;
  border: 1px solid #116c53;
}

.e-dialog.e-op2mise-dialog .e-footer-content {
  padding: 10px 30px 30px 20px !important;
}

.e-ca-grading-subject {
  container: GradingText / size;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}