.faCircleNotchSpin {
    font-size: 50px;
    color: #13ad83;
  }

  .divLoading{
    height: 150px;
    position: relative;
  }

  .pLoading{
    text-align:center;              
    position: absolute;              
    top: 23%;              
    left: 48%;
  }