.body {
    padding: 30px 50px;
}

.body label {
    font-weight: 300;
    font-size: 20px;
}

.smallText,
.fileName {
    font-weight: 200;
    font-size: 14px;
}

.fileName {
    margin-top: 36px;
    margin-left: 10px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.smallerText {
    font-weight: 400;
    font-size: 12px;
    color: #6D6D73;
    margin-top: 3px;
}

.buttons {
    text-align: right;
}

.description {
    font-weight: 100;
    font-size: 16px;
}

.confirmationText {
    font-weight: 300;
    font-size: 20px;
}

.saveButton {
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    color: #ffffff;
    background-color: #008662;
    border: none;
    border-radius: 4px;
  }

.cancelButton{  
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    background-color: #ffffff;
    color: #6d6d73;
    border: none;
    border-radius: 4px;
}

.marginTop30{
    margin-top: 30px;
}

.divHeader{
    text-align: center;
    padding-top: 20px;
  }

  .marginRight10{
    margin-right: 10px;
}
.successDiv {
    margin-top: 20px;
    border: 1px solid #e2e8f0;
  }

  .successBody {
    height: calc(100vh - 320px);
    overflow: auto;
    display: block;
  }
  
  .trBorder {
    border: 1px solid #e2e8f0;
  }
  
  .formCheck:checked {
    background-color: #008662 !important;
    border-color: #008662 !important;
  }
  
  .saveButton {
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    color: #ffffff;
    background-color: #008662;
    border: none;
    border-radius: 4px;
  }
  
  .saveButton:disabled {
    background-color: #b1b1b1 !important;
    cursor: not-allowed;
  }

.tableHeader{
    text-indent: 20px;
    padding: 5px 0;
    color: #656565;
    font-size: 13px;
    font-weight: bolder;
}

.tableHead, .tableBody .tableRow {
  display: table;
  width: 100%;
  table-layout: fixed;/* even columns width , fix width of table too*/
}

.width6{
  width: 6%;
}
.width35{
  width: 35%;
}
.error{
  color:#f44336;
}
.success{
  color:#4caf50;
}
.bold{
  font-weight: bolder;
}

.divMessage {
 text-align: center;
 font-size: 13px;
}

.divSelection{
  margin-top: 20px;
  font-size: 13px;
}

.divClose{
  text-align: right;         
  padding-top: 38px;
}
.faCircleNotchSpin {
    font-size: 50px;
    color: #13ad83;
  }

  .divLoading{
    height: 150px;
    position: relative;
  }

  .pLoading{
    text-align:center;              
    position: absolute;              
    top: 23%;              
    left: 48%;
  }