.select {
  position: relative;
}

.label {
  color: #343a40;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;
}

.selectButton {
  background-color: #008662;
  font-size: 12px;
  font-weight: 100;
  padding: 7px 12px;
  height: 32px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  text-align: start;
  display: flex;
}

.selectButton span {
  width: 100%;
}

.selectButton::after {
  content: url('../../assets/icons/caret-down.svg');
  float: right;
  margin-left: 5px;
  margin-top: -1px;
  width: 10px;
}

.selectButton:disabled {
  background-color: #b1b1b1;
  border: 1px solid #b1b1b1;
  cursor: not-allowed;
}

.optionList {
  position: absolute;
  z-index:999;
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #ced4da;
  overflow-y: auto;
}

.optionList::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;
  box-shadow: inset 0 0 0 2px #ffffff;
  border-left: 1px solid #d2d2d2;
}

.optionList::-webkit-scrollbar {
  border-left: 1px solid #d2d2d2;
  background-color: #ffffff;
  width: 14px;
  padding: 5px;
  border-radius: 0px 4px 4px 0px;
}

.optionItem {
  cursor: pointer;
  padding: 0px 9px 0px 9px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  color: #343a40;
}

.optionItem:hover {
  background-color: #ced4da;
}

.select span,
.optionItem {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.noOptionsWrapper {
  position: absolute;
  border: 1px solid #d3d2d2;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  z-index: 2;
  background-color: #ffffff;
}

.searchWrapper {
  position: absolute;
  width: 100%;
  padding: 5px;
  border: 1px solid #d3d2d2;
  background-color: #ffffff;
  z-index: 2;
}

.errorMessage {
  font-weight: 400;
  font-size: 12px;
  color: #ff7878;
}