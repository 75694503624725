.box {
  position: relative;
  width: 364px;
  height: calc(100vh - 141px);
  overflow-y: auto;
  margin-left: 15px;
  border-radius: 6px;
  padding: 15px 18px;
  background-color: #ffffff;
  border: 2px solid #008662;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.closeIcon {
  right: 0;
  width: 12.73px;
  height: 12.73px;
  position: absolute;
  filter: brightness(0) invert(0.7);
}

.headerText {
  font-size: 27px;
  margin-top: 20px;
  color: #000000;
}

.userPhoto {
  position: absolute;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #c7bdbd;
}

.uploadPhotoButton {
  width: 27.3px;
  height: 27.3px;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 3px;
  border: none;
  border-radius: 50%;
  background-color: #008662;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.uploadPhotoButton::before {
  content: url('../../assets/icons/Cloud-Upload.svg');
  line-height: 29px;
  margin-left: -1px;
}

.formLabel,
.formInput {
  display: block;
}

.formLabel {
  color: #343a40;
  font-size: 14px;
  line-height: 16.71px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.formInput {
  width: 100%;
  padding: 8px 13px;
  color: #343a40;
  font-size: 12px;
  line-height: 14.32px;
  border-radius: 4px;
  border: 1px solid #d3d2d2;
}

.formInput::placeholder {
  color: #b1b1b1;
}

.formInput:focus {
  outline: none;
}

.roleButton {
  width: 115px;
  cursor: pointer;
  color: #ffffff;
  background-color: #008662;
  height: 32px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border: none;
  border-radius: 4px;
  padding: 9px 14px;
}

.closeIcon,
.saveIcon,
.trashcanIcon,
.closeCircleIcon {
  cursor: pointer;
}

.saveIcon,
.trashcanIcon {
  margin-right: 40px;
}

.hasError {
  font-size: 12px;
  line-height: 14px;
  color: #ff7878;
  margin-top: 15px;
}

.required {
  color: #ff7878;
}

.loadingHeight {
  height: calc(100vh - 250px);
}
.divTableHeight {
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 250px);
}


.formCheck:checked {
  background-color: #008662 !important;
  border-color: #008662 !important;
}


.calendarHeight {
  height: calc(100vh - 250px);
  overflow: scroll;
}

.saveButton {
  height: 36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  padding: 11px 24px;
  color: #ffffff;
  background-color: #008662;
  border: none;
  border-radius: 4px;
  /* margin-right: 25px; */
}
.saveButton:disabled {
  background-color: #b1b1b1;
cursor: not-allowed;
}

.cancelButton {
  height: 36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  padding: 11px 24px;
  background-color: #f6f6f6;
  color: #5c636a;
  border: none;
  border-radius: 4px;
}

.divScheduleScores{
  color: #6D6D73;
  position: absolute;
  margin-top: 10px;
  width: calc(100vw - 440px );
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lblTitleCalendar{
  /* white-space: nowrap; */
  overflow: hidden;
  display: block;
  line-height: 15px;
  min-height: 15px;
  max-height: 30px;
  text-overflow: ellipsis;
  font-size: 14px;
  width:100%; 
}

.lblTitleCalendarNoWrap{
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  font-size: 14px;
  width:122px;
}

.lblTimeStampCalendar{
  color: #6D6D73;
  font-size: 12px;            
  width: 150px;        
}

.lblTimeStampCalendarNoWrap{
  color: #6D6D73;
  font-size: 12px;
  width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.divParent{
  position:relative;
   height:100%
}

.divChild{
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
}

.actionColumn {
  width: 120px !important;
  text-align: center;
}

.actionColumn img {
  cursor: pointer;
}

/* Header Schedule Banner Component Styles */
.scheduleBannerContainer {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.scheduleBannerContainer .channelContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.scheduleBannerContainer .dateRangeContainer {
  display: flex;
  align-items: center;
}

.divHeaderPadding {
  width: auto;
  padding: 0;
  padding-right: 10px;
  padding-top: 3px;
}
/* End of Header Schedule Banner Component Styles */