.e-sidebar-component-interstitial {
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.e-sidebar.e-sidebar-component-interstitial {
  background: var(--op2mise-color-white) !important;
  overflow-x: auto;
}

.e-sidebar-header-interstitial {
  background-color: var(--op2mise-color-white);
  padding: 16px 25px;
  display: flex;
  align-items: center; 
  position: sticky; 
  justify-content: flex-end;
}

.e-sidebar-title {
  font-size: 14px;
}

.e-sidebar-content-interstitial {
  padding: 0px 25px 8px 25px;
}

.e-sidebar-content-header-interstitial {
  margin-bottom: 32px;
}

.e-sidebar-close-icon {
  cursor: pointer;
}

.col-row {
  margin-bottom: 17px;
}
