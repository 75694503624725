
.break-banner-selector {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  
  .break-banner-channel-label {
    color: #6d6d73;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    margin-right: 5px;
    padding-right: 10px;
  }
  /* BREAK PATTERN EDITOR */
  .break-editor-wrapper {
    background-color: white;
    height: calc(100vh - 128px);
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
    border-radius: 0.5rem;
    padding: 40px 60px;
  }

  .break-editor-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }

  .break-import-export{
    display: flex;
    width: 60px;
    justify-content: space-between;
    align-items: center;
  }

  .break-editor-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .break-editor-form-fields label{
    color: #6d6d73 !important;
    font-size: 14px;
  font-weight: 800;
  }

  .break-editor-form-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 5px 0;
  }

  .break-editor-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
  }

  .content-level-input.dual-input{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    align-items: center;
    width: 70%;
  }

  .left-fields,
  .right-fields{
    display: flex;
    flex-direction: column;
  }

  .error-message{
    float: right;
    color: #ff7878;
    font-size: 12px;
    padding-top: 2px;
  }
