.e-sidebar-component {
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.e-sidebar-header {
  background-color: var(--op2mise-color-primary);
  padding: 7px 14px;
  color: var(--op2mise-color-white);
}

.e-sidebar-title {
  font-size: 14px;
}

.e-sidebar-sub-title {
  padding: 8px 16px
}

.col-row {
  margin-bottom: 17px;
}
