.e-date-range-wrapper{
    height: 26px;
}

.e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
    font-size: 17px;
}

.e-input-group input.e-input, .e-input-group.e-control-wrapper .e-range-icon {
    font-size: 20px;
    margin-top: -3px;
}