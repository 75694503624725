:root {
  /* OP2MISE THEME COLOR PALLETE */
  --op2mise-color-ash-gray: #D9D9D9;
  --op2mise-color-charcoal: #494949;
  --op2mise-color-charcoal-light: rgba(73, 73, 73, 0.55);
  --op2mise-color-dark-cyan: #0C212B;
  --op2mise-color-disabled: #CCCCCC;
  --op2mise-color-dove-gray: #6D6D73;
  --op2mise-color-highlight: #EEEEEE;
  --op2mise-color-light-blue-gray: #E2E8F0;
  --op2mise-color-light-gray: #F2F2F2;
  --op2mise-color-light-sky-blue: rgba(33, 150, 243, 0.12);
  --op2mise-color-mist-gray: #B3B6BB;
  --op2mise-color-pine-green: #005941;
  --op2mise-color-primary: #008662;
  --op2mise-color-primary-shade: #116C53;
  --op2mise-color-silver-gray: #CED4DA;
  --op2mise-color-silver-mist: #B1B1B1;
  --op2mise-color-steel-blue-gray: #787E82;
  --op2mise-color-transparent-green: rgb(0, 255, 0, 0.1);
  --op2mise-color-white: #FFFFFF;
  /* CA Grade Colors */
  --op2mise-color-green: #006837;
  --op2mise-color-yellow-green: #D9EF8B;
  --op2mise-color-yellow: #FEE08B;
  --op2mise-color-light-orange: #FDAE61;
  --op2mise-color-orange: #F46D43;
  --op2mise-color-light-blue: #5DADE2;
  --op2mise-color-dark-blue: #3498DB;
  --op2mise-color-red: #ff0000;
}