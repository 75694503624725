.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox{
  border: none;
  padding: 0 !important;
}

.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox > input.e-input {
  display: flex;
  align-items: center;
  background-color: #FFFFFF !important;
  border: 1px solid #CED4DA !important;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  padding: 0;
  height: 33px;
}

/* Remove the thick green bottom border */
.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox.e-valid-input::after, 
.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox.e-valid-input::before
 {
  content: none;
}

/* Removed bottom border when on focus */
/* .e-op2mise-numeric-textbox.e-input-focus{
  border: none !important;
} */
.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox.e-input-focus,
.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox.e-input-focus::after,
.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox.e-input-focus::before {
  border: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input).e-input-focus::before{
  width: 0 !important;
}

.e-control-wrapper.e-numeric.e-input-group.e-op2mise-numeric-textbox.e-disabled > input.e-input.e-disabled {
  background-color: #CED4DA !important;
  color: #B1B1B1 !important;
}
.e-op2mise-numeric-textbox.e-disabled{
  
}
