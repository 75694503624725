.playlist-banner {
  padding-top: 15px;
  padding-bottom: 5px;
  display: flex;
}

.playlist-banner-header-text {
  flex: 3;
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.playlist-banner-channel-selector {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.playlist-banner-channel-label {
  color: #6d6d73;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  margin-right: 5px;
  padding-right: 10px;
}

.playlist-content-wrapper {
  display: flex;
  padding: 20px 0px;
}