.input {
  display: block;
}

.label {
  color: #343a40;
  font-size: 14px;
  line-height: 16.71px;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  padding: 8px 13px;
  color: #343a40;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #d3d2d2;
}

.input:focus {
  width: 100%;
  padding: 8px 13px;
  color: #343a40;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #007662;
}

.input::placeholder {
  color: #b1b1b1;
}

.input:focus {
  outline: none;
}

.input:disabled {
  cursor: not-allowed;
}

.errorMessage {
  font-weight: 400;
  font-size: 12px;
  color: #ff7878;
}
