.successDiv {
    margin-top: 20px;
    border: 1px solid #e2e8f0;
  }

  .successBody {
    height: calc(100vh - 300px);
    overflow: auto;
    display: block;
  }
  
  .trBorder {
    border: 1px solid #e2e8f0;
  }
  
  .formCheck:checked {
    background-color: #008662 !important;
    border-color: #008662 !important;
  }
  
  .saveButton {
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    color: #ffffff;
    background-color: #008662;
    border: none;
    border-radius: 4px;
  }
  
  .saveButton:disabled {
    background-color: #b1b1b1 !important;
    cursor: not-allowed;
  }

.tableHeader{
    text-indent: 20px;
    padding: 5px 0;
    color: #656565;
    font-size: 13px;
    font-weight: bolder;
}

.tableHead, .tableBody .tableRow {
  display: table;
  width: 100%;
  table-layout: fixed;/* even columns width , fix width of table too*/
}

.width5{
  width: 5%;
}
.width6{
  width: 6%;
}
.width11{
  width: 11%;
}
.width12{
  width: 12%;
}
.width13{
  width: 13%;
}
.width21{
  width: 21%;
}
.width22{
  width: 22%;
}
.width32{
  width: 32%;
}
.width35{
  width: 35%;
}
.error{
  color:#f44336;
}
.success{
  color:#4caf50;
}
.bold{
  font-weight: bolder;
}

.divMessage {
 text-align: center;
 font-size: 13px;
}

.divSelection{
  margin-top: 20px;
  font-size: 13px;
}

.divClose{
  text-align: right;         
  padding-top: 38px;
}