.textLink:hover {
    text-decoration: underline;
    cursor: pointer;   
}
.textLink{
    margin-left: 12px;
}
.saveButton{
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    background-color: #008662;
    color: #ffffff;
    border: none;
    border-radius: 4px;  
    margin-top: 20px;
}

.saveButtonDisabled{
  height: 36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 100;
  padding: 11px 24px;
  background-color: gray;
  color: #ffffff;
  border: none;
  border-radius: 4px;  
  margin-top: 20px;
}

.startTimeInput div div div {
  padding: 7px 10px;
}

.startTimeInputCut div  div {
  padding: 7px 10px;
}

.saveButton:disabled {
  background-color: #b1b1b1;
  cursor: not-allowed;
}

.cancelButton {
    height: 36px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 100;
    padding: 11px 24px;
    color: #5c636a;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
}

.divFooterModal{
  float: right;
  margin-top: 20px;
}
.optionItem {
  cursor: pointer;
  padding: 0px 9px 0px 9px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  color: #343a40;
}
  
.optionItem:hover {
    background-color: #ced4da;
}

/* .select span, */
.optionItem {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.slotTypeWidth {
  width: 100px;
}

.dateTimeWidth {
  width: 25%;
}

.dateTimeWidthLive {
  width: 33%;
}

.durationInput{
  height: 33px;
  width: 100%;
  border: 1px solid #CED4DA !important;
  border-radius: 4px 4px 4px 4px;
  font-size: 12px;
  text-indent: 5px;
  color: #343a40;
}
.durationInput:focus {
  outline: none;
}

.formCheck {
  margin-top: 13px;
  margin-right: 5px;
}

.formCheck:checked {
  background-color: #008662;
  border-color: #008662;
  box-shadow: none;
}

.formInput {
  width: 55px;
  padding: 0px 12px;
  color: #343a40;
  font-size: 12px;
  line-height: 41px;
  height: 41px;
  border-radius: 4px;
  border: 1px solid #d3d2d2;
  margin-left: 5px;
  margin-right: 5px;
}

.formInput::placeholder {
  color: #b1b1b1;
}

.formInput:focus {
  outline: none;
}

.formInput:disabled {
  cursor: not-allowed;
  background-color: #CED4DA;
  border-color: #CED4DA;
}

.formInputRedBorder {
  border-color: #FF7878;
}

.formCheckWrapper,
.weeklyPatternLabelWrapper,
.weekdaysWrapper {
  display: flex;
  margin-left: 15px;
}

.weekdaysWrapper {
  margin-top: 15px;
}

.label {
  color: #343A40;
  font-weight: 400;
  font-size: 12px;
  margin-top: 12px;
}

.numberOfSlotsLabel {
  color: #343A40;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top:7px !important;
}

.checkbox {
  margin-right: 3px;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  margin-top: 2px;
  padding: 2px 1px;
}

.checkboxLabel {
  color: #343A40;
  font-weight: 400;
  font-size: 12px;
  margin-right: 8px;
}

.contentWrapper {
  height: calc(100vh - 185px);
}
.formCheckMargin{
  margin-top: 0.1em !important;
}

.formPaddingTopRadio{
  padding-top: 3px;
}

.formPaddingLeft{
  padding-left: 5px;
}

.tabsSchedule{
  box-shadow: none !important;
  height: calc(100vh - 355px) !important;
  border-top: 1px solid #f1e1e1;
  padding: 5px 0px !important;
  overflow: visible !important;
}
.tabsScheduleGroups{
  box-shadow: none !important;
  border-top: 1px solid #f1e1e1;
  height:calc(100vh - 510px) !important; 
  padding: 5px 0px !important;
  overflow: visible !important;
}
.formMarginRight{
  margin: 0.25rem!important;
}
.tabsScheduleHeaderActive{
  border: 1px solid #f1e1e1;
  border-bottom: none;
  border-radius: 4px;
}
.programDetailsHeight{
   width: 100%;
   height: 200px !important;
}
.recurrenceOptions{
  position: fixed !important;
}
.divTabsMargin{
  margin: 0.25rem!important;
}

.rowTitlesTable{
  border: 1px solid #e2e8f0;
}

.startDateDiv {
  width: 100%;
  margin-right: 15px;
}

.startTimeDiv {
  width: 100%;
}

.smallLabel {
  margin-top: 2px;
}

.startDivLabel {
  font-size: 12px;
  margin-bottom: 5px;
}

.customTDWidth {
  width: 70px;
  display: flex;
}

.recurrenceFormInput {
  width: 66px;
  padding: 0px 12px;
  color: #343a40;
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #d3d2d2;
 
}

input.recurrenceFormInput:focus {
  outline-width: 0;
}
input.recurrenceFormInput:disabled {
  background-color: #CED4DA;
}
