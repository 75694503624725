div.op-date-picker-wrapper {
  align-items: center;
  background-color: var(--op2mise-color-white) !important;
  border: 1px solid var(--op2mise-color-silver-gray) !important;
  border-radius: 4px;
  display: flex;
  height: 36px;
  min-width: 145px;
  padding-left: 12px;
  width: 100%;
}

.op-date-picker-wrapper .e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border-width: 0px !important;
};