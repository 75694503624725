.select {
  position: relative;
}

.label {
  color: #343a40;
  font-weight: 400;
  font-size: 14px;
}

.selectButton {
  background-color: #008662;
  font-size: 12px;
  font-weight: 100;
  padding: 7px 12px;
  height: 32px;
  border: 1px solid #008662;
  border-radius: 4px;
  color: #ffffff;
  text-align: start;
  display: flex;
}

.selectButton span {
  width: 100%;
  overflow-x: hidden;
  /* text-overflow: ellipsis; */
}

.caretDown {
  margin-top: 5px;
}

.selectButton:disabled {
  background-color: #b1b1b1;
  border: 1px solid #b1b1b1;
  cursor: not-allowed;
}

.optionList {
  position: absolute;
  z-index: 3;
  background-color: #ffffff;
  margin-top: -2px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #ced4da;
  overflow-y: auto;
}

.optionItem {
  cursor: pointer;
  padding: 0px 9px 0px 9px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  color: #343a40;
}

.optionItem:hover {
  background-color: #ced4da;
}

.select span,
.optionItem {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-y: hidden;
}

.searchInputGroup {
  display: flex;
  padding: 5px;
  position: relative;
  /* position: absolute; */
  z-index: 1;
  background-color: #ffffff;
  margin-top: -1px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #ced4da;
}

.searchInput {
  width: 100%;
  padding: 8px 0px 8px 13px;
  color: #343a40;
  font-size: 12px;
  line-height: 14.32px;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #d3d2d2;
  border-right: none;
}

.searchInputGroup div {
  background-color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #d3d2d2;
  border-left: none;
}

.searchIcon,
.closeIcon {
  cursor: pointer;
  height: 14.7px;
  width: 14.7px;
  margin-top: 7px;
  margin-right: 10px;
  margin-left: 10px;
}

.searchIcon {
  content: url('../../assets/icons/search.svg');
}

.closeIcon {
  content: url('../../assets/icons/close.svg');
}

.searchInput::placeholder {
  color: #b1b1b1;
}

.searchInput:focus {
  outline: none;
}

.searchInput:disabled {
  cursor: not-allowed;
}

.noResult {
  padding: 0px 9px 0px 9px;
  height: 25px;
  line-height: 25px;
  font-size: 13px;
  color: #343a40;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center !important;
}

.disabledStyle2 {
  background-color: #CED4DA !important;
  border: 1px solid #CED4DA !important;
  color: #B1B1B1 !important;
}

.disabledStyle2::after {
  filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(7%) hue-rotate(146deg) brightness(85%) contrast(83%) !important;
}