.timeInput{
    width: 100%;
}

.timeInputWrapper {
    display: flex;
    width: 100%;
    width: 54px;
    color: #6D6D73;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 5.5px 10px;
    overflow: hidden;
    width: 100%;
}

.timeInputWrapper:hover {
    cursor:text;
}

.hourInput {
    text-align: end;
    width: 19px;
    padding-right: 1px;
}

.minuteInput {
    width: 23px;
    padding-left: 1px;
}

.hourInput, .minuteInput {
    border: none;
    color: #343a40;
    font-size: 12px;
}

.hourInput:focus, .minuteInput:focus {
    outline: none;
}

.hourInput::placeholder, .minuteInput::placeholder {
    color: #B1B1B1;
}

.inputDivider {
    margin-top: 1px;
    font-size: 12px;
}

.invalidInput {
    border-color: #FF7878;
}

.errorMessage {
    color: #FF7878;
    font-size: 12px;
    margin-top: 5px;
}

.disabledInput,
.disabledInput .hourInput,
.disabledInput .minuteInput {
    border-color: #CED4DA;
    background-color: #CED4DA;
    color: #B1B1B1;
    cursor: not-allowed !important;
}