div.view-schedule-date-picker-wrapper{
  min-width: 145px;
  width: 100%;
  background-color: var(--op2mise-color-white);
  border: 1px solid var(--op2mise-color-silver-gray) !important;
  border-radius: 4px;
  padding-left: 6px;
}

.view-schedule-date-picker-wrapper:has(input.e-control.e-datepicker.e-lib.e-input.e-disabled){
  background-color: #CED4DA !important;
}

.view-schedules-date-picker > .e-control.e-datepicker.e-lib.e-input.e-keyboard { 
  font-size: 12px;
  color: var(--op2mise-color-dove-gray);
}

span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input.view-schedules-date-picker{
  border: none !important;
}

span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input.view-schedules-date-picker::after,
span.e-input-group.e-control-wrapper.e-date-wrapper.e-valid-input.view-schedules-date-picker::before{
  content: none;
}

.view-schedules-date-picker.e-input-focus{
  border: solid 1px #AD0101;
  outline: none;
}
.e-input-group.e-control-wrapper.view-schedules-date-picker e-date-wrapper.e-valid-input.e-non-edit.e-disabled > input.e-control.e-datepicker.e-lib.e-input.e-disabled {
  background: #CED4DA !important;
  color: #B1B1B1 !important;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left) {
  border: none !important;
}