span.e-control-wrapper.e-mask.e-input-group.op-masked-input.e-valid-input {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6D6D73;
  border: 1px solid #CED4DA !important;
  border-radius: 4px;
  padding: 0 10px;
  width: 100%;
  height: 36px;
  font-size: 12px;
}

span.e-control-wrapper.e-mask.e-input-group.op-masked-input {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6D6D73;
  border: 1px solid #CED4DA !important;
  border-radius: 4px;
  padding: 0 10px;
  width: 100%;
  height: 36px;
  font-size: 12px;
}

span.e-control-wrapper.e-mask.e-input-group.op-masked-input:focus-within {
  border: 1px solid #008662 !important;
}

.op-masked-input .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
  font-size: 12px !important;
  color: #343a40;
}

.op-masked-input .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input::placeholder {
  font-size: 12px !important;
  color: #b1b1b1;
}