.inputWrapper { 
    display: flex;
    width: 100%;
    width: 54px;
    color: #6D6D73;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    padding: 5.5px 10px;
    overflow: hidden;
    width: 100%;
}

.inputWrapper:focus {
    outline: none;
}

.endTimeInputWrapper input { 
    width: 115px;
}

.startTimeInputWrapper input,
.durationInputWrapper input,
.maxCountInputWrapper { 
    width: 65px;
}

.switchClass::after {
    position: absolute;
    top: 2px;
}

.switchClass::before {
    top: 1.5px;
}

.modalInput::placeholder {
    color: #B1B1B1;
}