.userProfileWrapper {
  border: 2px solid #008564;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0px 7px 15px 0px #00000040;
  overflow: hidden;
  margin-left: 10px;
  margin-top: -9px;
  cursor: pointer;
  position: relative;
}

.userProfile {
  height: 100%;
  object-fit: cover;
}

.userProfileShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset #00000040 0px 3px 4px 0px;
  border-radius: 50%;
  top: 0;
  left: 0;
}

.triangleShape {
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #FFFFFF;
  transform: rotate(45deg);
  margin-left: 25px;
  margin-top: -2px;
  z-index: 1001;
}

.userDetailsWrapper {
  position: absolute;
  width: 211px;
  background-color: #FFFFFF;
  right: 0;
  margin-top: 5px;
  z-index: 1000;
  padding: 30px 40px;
  border-radius: 5px;
  box-shadow: 0px 7px 15px 0px #00000040;
}

.username, .title {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.username {
  font-size: 13px;
  color: #6D6D73;
  font-weight: bold;
  margin-bottom: 5px;
}

.title {
  font-size: 12px;
  color: #6D6D73;
  margin-top: -5px;
}

.divider {
  background: #CED4DA;
  width: 100%;
  height: 1px;
}

.profileLink, .signoutLink {
  font-size: 13px;
  color: #008662;
  font-weight: bold;
  cursor: pointer;
}

.profileLink {
  margin-top: 30px;
  margin-bottom: 10px;
}

.signoutLink {
  margin-top: 14px;
}