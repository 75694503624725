/** Toolbar component */
.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child, .e-toolbar .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child {
  margin-left: 0px;
}

.e-upload .e-file-select-wrap, .e-bigger.e-small .e-upload .e-file-select-wrap {
  padding: 16px 12px 16px 12px;
}

.schedule-control-section {
  width: 100%;
}

.e-toolbar .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-item:last-child, .e-toolbar .e-toolbar-items:first-child:not(.e-toolbar-multirow) > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 12px;
}

.e-tbar-btn-text {
  margin-left: 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child, .e-toolbar .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child {
  margin-left: 4px;
}

/** AppBar Component*/
.e-appbar {
  padding: 8px 14px 8px 8px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  min-height: 50px;
}

/** Scheduler Component*/
.schedule-control-section{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px;
}
/** Scheduler column widths */
.sf-theme-op2mise.e-schedule .e-vertical-view .e-date-header-wrap table col,
.sf-theme-op2mise.e-schedule .e-vertical-view .e-content-wrap table col {
    width: 200px;
}

.sf-theme-op2mise.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.sf-theme-op2mise.e-schedule .e-vertical-view .e-work-cells {
    height: 50px;
}

.sf-theme-op2mise.e-schedule .e-month-view .e-work-cells,
.sf-theme-op2mise.e-schedule .e-month-view .e-date-header-wrap table col {
    width: 200px;
}

.sf-theme-op2mise.e-schedule .e-month-view .e-work-cells {
    height: 200px;
}

.sf-theme-op2mise.e-schedule .e-timeline-month-view .e-content-wrap table col,
.sf-theme-op2mise.e-schedule .e-timeline-view .e-content-wrap table col {
    width: 100px;
}

.sf-theme-op2mise.e-schedule .e-timeline-view .e-work-cells,
.sf-theme-op2mise.e-schedule .e-timeline-month-view .e-work-cells {
    height: 600px;
}

/** Scheduler Component*/
/** Scheduler column widths */
.sf-theme-op2mise-2.e-schedule .e-vertical-view .e-date-header-wrap table col,
.sf-theme-op2mise-2.e-schedule .e-vertical-view .e-content-wrap table col {
    width: 100px;
}

.sf-theme-op2mise-2.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.sf-theme-op2mise-2.e-schedule .e-vertical-view .e-work-cells {
    height: 50px;
}

.sf-theme-op2mise-2.e-schedule .e-month-view .e-work-cells,
.sf-theme-op2mise-2.e-schedule .e-month-view .e-date-header-wrap table col {
    width: 100px;
}

.sf-theme-op2mise-2.e-schedule .e-month-view .e-work-cells {
    height: 100px;
}

.sf-theme-op2mise-2.e-schedule .e-timeline-month-view .e-content-wrap table col,
.sf-theme-op2mise-2.e-schedule .e-timeline-view .e-content-wrap table col {
    width: 100px;
}

.sf-theme-op2mise-2.e-schedule .e-timeline-view .e-work-cells,
.sf-theme-op2mise-2.e-schedule .e-timeline-month-view .e-work-cells {
    height: 600px;
}

.e-schedule .e-month-view .e-date-header {
  cursor: pointer;
  display: table;
  height: 20px;
  line-height: 20px;
  margin: 3px 3px 2px;
  text-align: center;
}

.e-schedule .e-vertical-view .e-header-cells {
  font-size: 12px;
  height: 60px;
  padding: 3px 15px 2px;
}

.custom-quick-info-header {
  background: var(--op2mise-color-primary);
  margin-bottom: 25px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.custom-quick-info-icon-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 0 20px;
}

.custom-quick-info-button-close {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.custom-quick-info-button-close :hover {
  padding: 3px;
  /* background: rgba(12, 33, 43, 0.1); */
  background: rgba(var(--color-sf-on-surface), 0.05);
}

.custom-quick-info-icon {
  color: #FFF;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
}

.custom-quick-info-title-wrapper {
  padding: 0px 24px 0 6px;
}

.custom-quick-info-title {
  background: transparent;
  border-radius: 0;
  border-left: 0;
  color: var(--op2mise-color-white);
  padding: 8px 8px 8px 14px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 41px;
  cursor: default;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
}

.e-disable-dates{
display: none;
}
.e-left-indent{
  border-color: rgba(var(--color-sf-outline-variant));
  border-style: solid;
  border-width: 0 1px 1px 0;
}