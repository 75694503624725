.closeIcon {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(73%) sepia(2%) saturate(54%) hue-rotate(314deg) brightness(97%) contrast(91%);
  height: 15px;
  margin-top: 6px;
  padding-right: 5px;
}

.searchFilterOption {
  color: #343a40;
  cursor: pointer;
  font-size: 13px;
  height: 25px;
  line-height: 25px;
  overflow: hidden;
  padding: 0px 9px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.searchFilterOption:hover {
  background-color: #ced4da;
}

.searchFilterOptionContainer {
  background: var(--op2mise-color-white);
  border: 1px solid #d3d2d2;
  max-height: 225px;
  max-width: 430px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.searchFilterOptionContainerHidden {
  display: none;
}

.searchIcon {
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(73%) sepia(2%) saturate(54%) hue-rotate(314deg) brightness(97%) contrast(91%);
  height: 18px;
  margin-top: 4px;
}

.searchIconWrapper {
  background: #ffffff;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  display: flex;
  height: 37px;
  padding-bottom: 3px;
  padding-right: 10px;
  padding-top: 5px;
}

.searchIcon:hover,
.closeIcon:hover {
  filter: brightness(0) saturate(100%) invert(25%) sepia(73%) saturate(2421%) hue-rotate(150deg) brightness(98%) contrast(103%);
  outline: none;
}

.searchInput {
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px 0px 0px 4px;
  border-right: none;
  box-sizing: border-box;
  color: #6d6d73;
  font-size: 12px;
  height: 37px;
  line-height: 14px;
  outline: none;
  padding: 3px 10px;
  width: 100%;
}

.searchInput::placeholder {
  color: #b1b1b1;
}

.searchInput:disabled {
  background: #e9ecef;
}

.searchInputWithFilter {
  background: #ffffff;
  border: 1px solid #ced4da;
  border-left: none;
  border-radius: 0px 0px 0px 0px;
  border-right: none;
  box-sizing: border-box;
  box-shadow: none;
  color: #6d6d73;
  font-size: 12px;
  height: 37px;
  line-height: 14px;
  outline: none;
  padding: 3px 10px 3px 3px;
  width: 100%;
}

.selectedSearchOption {
  align-items: center;
  background: #008662;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-size: 12px;
  height: 27px;
  padding: 0 10px;
}

.selectedSearchOptionContainer {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-bottom-left-radius: 4px;
  border-right: none;
  border-top-left-radius: 4px;
  display: flex;
  height: 37px;
  justify-content: flex-start;
  padding: 10px 0 10px 10px;
  white-space: nowrap;
}

.smallInput {
  height: 30px;
}

.smallInput .closeIcon {
  margin-top: 2px;
}

.smallInput .searchIcon {
  margin-top: 0px;
}
